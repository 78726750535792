<template>
  <v-app>
    <v-main>

      <router-view/>
  
             <alert-popup v-if="isMobile" />
    </v-main>
         <div style="height:1000px"></div>
  </v-app>
</template>

<script>
import AlertPopup from './components/AlertPopup.vue';

export default {
  components: { AlertPopup },
  name: 'App',
  data: () => ({
    //
  }),
  computed: {
    isMobile(){
    let isMobile = window.matchMedia("only screen and (max-width: 760px)").matches;
      return isMobile
    }
  },
    mounted() {
      
     },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;0,900;1,400&display=swap');

$font-family: 'Lato';

.v-application {
 [class*='text-'] {
    font-family: $font-family, sans-serif !important;
  }
  font-family: $font-family, sans-serif !important;
 }

</style>
