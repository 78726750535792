<template>
<div>
  <div class="jitsi-iframe " id="jitsi" ></div>
  <div 
    v-if="!inCall"
    class="pre-call-container"
  >
    <v-img
    href="https://spacein.co.uk"
    transition="fade-transition"
    class="pre-call"
    src="@/assets/SPACEIN_Guest.svg"
    ></v-img>
  </div>

     <v-img
      href="https://spacein.co.uk"
    transition="fade-transition"
    class="joined"
    v-else
    src="@/assets/SPACEIN_Guest.svg"
    ></v-img>


</div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'JitsiIframe',
  data() {
    return {
      jaasToken: "",
      inCall: false,
      rooms: {
        experience: '37c8d934-72ed-44e7-80e8-c7ae7cf194cf', // MCCGLC
        engagement: '29e91067-3161-421a-923c-608022671b5f',  // MCCGLC
        emotion: '9e3d81de-b766-4601-b2ca-c3f2944037c8',  // MCCGLC
        adventure: 'e7b346a3-63e8-4c7b-88a6-33fd3e14429c',  // MCCGLC
        test: 'testing-richard-1234',  // TEST
        SMR: 'f5e5a68e-2605-438b-ac31-56a8931797e2',  // The Information Lab
        SBR: 'e904384c-dd0d-4e88-a42a-3b54c16f9924',  // The Information Lab
        FMR: '11d0c2ac-d1e3-4c27-ad46-2f91996e2c2e',  // The Information Lab
        Freedom: '73b5991d-207b-4957-9ad0-a4435c9b3a80',  // The Information Lab 
      }
    };
  },
  computed: {
    usersName() {
      let name = '';
      if (this.$route.params.displayName) {
        name = this.$route.params.displayName;
      }
      return name;
    },
    usersEmail() {
      let email = '';
      if(this.$route.params.email){
          email = this.$route.params.email; 
      }
      return email;
    },
    usersAvatar() {
        let avatar = '';
      if(this.$route.params.avatar){
          avatar = this.$route.params.avatar; 
      }
      return avatar;
    },
    id() {
      return this.rooms[this.$route.params.roomID]
    },
    topic() {
      return this.$route.params.roomID
    },

  },
  watch: {
    currentRoomData() {
      this.openJitsiIframe()
    },
  },
  created() {
      this.$mixpanel.track('View', {
      'site': "Guest",
      'page': "Jitsi",
      'space': this.$route.params.roomID
    });
  },
  methods: {
    openJitsiIframe() {
      // let ifJitsiOpen = false;
      let jitsiIframe = document.querySelector('#jitsi iframe');
      if (jitsiIframe) {
        // ifJitsiOpen = true;
        jitsiIframe.remove()
      }
      // only add iframe if not already added
      // if (!ifJitsiOpen) {
        console.log(this.jaasToken)
        const options = {
          roomName: `vpaas-magic-cookie-605c24f7880c40ea9f86c029ef859dc8/${this.id}`,
          parentNode: document.querySelector('#jitsi'),
          jwt: this.jaasToken,
          configOverwrite: {
            startWithAudioMuted: false,
            startWithVideoMuted: false,
            constraints: {
              video: {
                aspectRatio: 16 / 9,
                // height: {
                //   ideal: 720,
                //   max: 720,
                //   min: 240
                // }
              }
            },
            enableLayerSuspension: true,
            prejoinPageEnabled: true,
            disableThirdPartyRequests: false
          },
          interfaceConfigOverwrite: {
            ENABLE_FEEDBACK_ANIMATION: true,
            MAXIMUM_ZOOMING_COEFFICIENT: 1,
            APP_NAME: 'SPACEIN',
            BRAND_WATERMARK_LINK: 'https://spacein.co.uk',
            DEFAULT_BACKGROUND: '#000000',
            DEFAULT_LOCAL_DISPLAY_NAME: 'me',
            DEFAULT_REMOTE_DISPLAY_NAME: 'Fellow participant',
            DISABLE_DOMINANT_SPEAKER_INDICATOR: true,
            DISABLE_FOCUS_INDICATOR: true,
            DISABLE_PRESENCE_STATUS: true,
            DISABLE_VIDEO_BACKGROUND: true,
            HIDE_DEEP_LINKING_LOGO: false,
            HIDE_INVITE_MORE_HEADER: false,
            LOCAL_THUMBNAIL_RATIO: 16 / 9,
            SHOW_CHROME_EXTENSION_BANNER: false,
            JITSI_WATERMARK_LINK: 'https://spacein.co.uk',

            TOOLBAR_BUTTONS: [
              'microphone',
              'camera',
              'closedcaptions',
              'desktop',
              'embedmeeting',
              'fullscreen',
              'fodeviceselection',
              'hangup',
              // 'profile',
              // 'chat',
              // 'recording',
              'livestreaming',
              'etherpad',
              'sharedvideo',
              'shareaudio',
              'settings',
              'raisehand',
              'videoquality',
              'filmstrip',
              'invite',
              'feedback',
              'stats',
              // 'shortcuts',
              'tileview',
              'select-background',
              // 'download',
              // 'help',
              'mute-everyone',
              'mute-video-everyone',
              'security'
            ],
          }
        };
        var self = this
        const api = new JitsiMeetExternalAPI("8x8.vc", options);
        api.executeCommand('subject', this.topic);
        api.addListener('readyToClose', function(){
          self.jitsiLeaveButtonHandler()
        });
        let changeStatus = this
        api.addListener('videoConferenceJoined', function(){
          changeStatus.inCall = true
          console.warn('IN CALLLLLL', this.inCall)
        });

      // }
    },
    getJaaStoken(){
      var moderator = "false"
     
      console.log("Are you moderator?", moderator)
      axios.post('https://jaas.spacein.co.uk/api/request/',
        {
          "id": this.usersEmail,
          "name": this.usersName,
          "email": this.usersEmail,
          "avatar": this.usersAvatar,
          "appid": "vpaas-magic-cookie-605c24f7880c40ea9f86c029ef859dc8",
          "kid": "vpaas-magic-cookie-605c24f7880c40ea9f86c029ef859dc8/9ba40b",
          "moderator": moderator
        })
      .then(response => {
        this.jaasToken = response.data

        this.openJitsiIframe();
      })
    },
    jitsiLeaveButtonHandler() {
        this.$router.push('/call-ended')
    },
  },
  mounted() {
    this.getJaaStoken();
    // window.scrollBy(0, 1000);
    //allow for current users room to update and stuff when space switching
  },
};
</script>

<style scoped>

  #jitsi {
    position: fixed;
    top: 0;
   width: 100vw;
   height: 100vh
  }

  .pre-call-container {
  position: fixed;
  width: 560px;
  height: 100vh;
  top: 0;
  left: 0;
  pointer-events: none
}

  .pre-call {
    position: relative;
    margin: auto;
    width: 250px;
    top: calc(50vh - 300px);
  }

  @media only screen and (max-width: 1000px) {

    .pre-call-container {
      position: fixed;
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
    }

    .pre-call {
      position: relative;
      margin: auto;
      width: 250px;
      top: 100px;
    }

  }

  @media only screen and (max-height: 700px) {
    .pre-call-container {
      display:none;
    }
  }

  .joined {
    position: fixed;
    width: 200px;
    top: 40px;
    left: 40px;
  }
</style>
