<template>
<div class="callEnd">
 <v-img
    max-width="300"
    src="@/assets/logo.svg"
    class="mx-auto mt-16"
    ></v-img>

  <v-card
    :loading="loading"
    class="mx-auto my-12"
    max-width="374"
  >
    <v-img
      height="250"
      src="@/assets/endcallbanner.png"
    ></v-img>

    <v-card-title class='callEnd'>Didn't mean to leave the meeting?</v-card-title>

    <v-btn
        color="#fedb00"
        outlined
        class='ml-4'
        @click="rejoin"
      >
        Rejoin
      </v-btn>

    <v-card-text>
      <v-row
        align="center"
        class="mx-0"
      >
      </v-row>

    </v-card-text>

    <v-divider class="mx-4"></v-divider>

    <v-card-title class='callEnd'>Why not create your own SPACEIN Community?</v-card-title>

      <v-btn
        color="#fedb00"
        text
        @click="joinSpacein"
      >
        Learn more about SPACEIN
      </v-btn>
  </v-card>

  </div>
</template>

<script>
  export default {
    data: () => ({
      loading: false,
      selection: 1,
    }),

    methods: {
      rejoin () {
          this.$router.go(-1)
      },
      joinSpacein () {
          window.location.href = "https://spacein.co.uk";
      },
    },
  }
</script>

<style scoped>
.callEnd {
    word-break: normal!important;
}
</style>