<template>
  <div class="livestream" >
    <div class="livestream-video">
      <video class="livestream-video__element" ref="element" @click="togglePause" />
    </div>
    <div class="livestream-controls" v-show="player">

      <div class="livestream-button" @click="togglePause">
        <img v-if="paused" src="@/assets/play.svg"/>
        <img v-else src="@/assets/pause.svg"/>
      </div>

      <div class="livestream-controls__volume">
        <img
          :src="require(`@/assets/volume${volumeIcon}.svg`)"
          @click="player.muted(!muted)"
        />
        <input type="range" min="0" max="1" step="0.01" :value="volume" @input="player.volume($event.target.value)" />
      </div>

      <div style="margin-left: auto" class="livestream-button" @click="toggleFullscreen">
        <img src="@/assets/fullscreen.svg"/>
      </div>

      
      
    </div>
  </div>
</template>

<script>
// todo: move to hls.js
import videojs from 'video.js';

export default {
  name: 'Livestream',
  // todo: fix error on refresh
  props: {
    roomData: Object
  },
  data: () => ({
    player: null,
    paused: true,
    muted: false,
    volume: 0
  }),
  methods: {
    toggleFullscreen(){
      if(!document.fullscreenElement) document.documentElement.requestFullscreen().catch(console.error);
      else document.exitFullscreen();
    },
    togglePause(){
      if(this.paused) this.player.play();
      else this.player.pause();
    },
    syncWithPlayer(){
      this.paused = this.player.paused();
      this.volume = this.player.volume();
      this.muted = this.player.muted();
    },
  },
  computed: {
    volumeIcon(){
      if(this.muted) return 'mute';
      if(this.volume > 0.75) return '100';
      if(this.volume > 0.50) return '75';
      if(this.volume > 0.25) return '50';
      if(this.volume >= 0) return '25';
      else return '100'
    } 
  },
  mounted(){
    this.player = videojs(this.$refs.element, {
      sources: [this.roomData.videoLink],
      autoplay: true,
      muted: true,
    });

    this.player.on(['play', 'pause', 'volumechange'], this.syncWithPlayer);


  },
  beforeDestroy() {
    this.player?.dispose();
  }
};
</script>


<style lang="scss">
// sadly has to be unscoped to get this vjs styling to work
.vjs {
    &-modal-dialog-content {
        box-sizing: border-box;
    }

    &-loading-spinner {

        &::after,
        .vjs-control-text {
            display: none;
        }
    }
}

.status-bar.current-space-page .livestream {
    display: flex;
}

.livestream {
    line-height: 1;
    display: flex;
    height: 100%;
    width: 100%;
    flex-flow: column;
    color: white;

    &-video {
        position: relative;
        flex-grow: 1;
        overflow: hidden;

        &__element {
            width: 100%;
            height: 100%;
            overflow: hidden;

            video {
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center;
            }
        }
    }

    &-button {
        cursor: pointer;
        justify-content: space-around;
        background-color: rgba(255, 255, 255, 0.1);
        transition: background-color 0.25s;
        box-sizing: border-box;
        min-width: 58px;

        &:hover {
            background-color: rgba(255, 255, 255, 0.25);
        }

        img {
            height: 22px;
        }
    }

    &-controls {
        font-weight: bold;
        background-color: #000000;
        border-top: 3px solid #FD3746;
        height: 58px;
        display: flex;
        flex-shrink: 0;

        >* {
            height: 100%;
            display: flex;
            align-items: center;
        }

        &__volume {
            padding: 0 28px;

            img {
                margin-right: 16px;
                cursor: pointer;
            }

            input {
                width: 145px;
                margin: 0;
                border: 0;
                -webkit-appearance: none;
                background-color: white;
                height: 3px;
                border-radius: 5px;

                &::-webkit-slider-thumb {
                    -webkit-appearance: none;
                    appearance: none;
                    width: 15px;
                    height: 15px;
                    background-color: white;
                    border-radius: 100%;
                    cursor: pointer;
                }
            }
        }

        &__live {
            &::before {
                content: "";
                background: #FF3746;
                width: 7px;
                height: 7px;
                border-radius: 100%;
                margin-right: 5px;
            }

            img {
                margin: 0 10px;
            }
        }


        &__leave {
            padding: 0 20px;
            background-color: rgba(255, 63, 79, 0.5);

            &:hover {
                background-color: rgba(255, 63, 79, 0.75);
            }
        }
    }
}
</style>