<template>
  <div>

  </div>
</template>

<script>

  export default {
    name: 'Home',
    created() {
    window.location.href = "https://spacein.co.uk";
  },
  }
</script>
