<template>
  <div class="fixedPosition d-flex">
    <side-bar-desktop :details="roomInfo" v-if="this.$vuetify.breakpoint.lgAndUp" />
    <livestream v-if="roomInfo.streamActive" :roomData="roomInfo" />
    <image-holder v-else :roomData="roomInfo" />
    
  </div>
</template>

<script>

import SideBarDesktop from '@/components/SideBarDesktop.vue'
import Livestream from '@/components/Livestream.vue'
import ImageHolder from '@/components/ImageHolder.vue'

  export default {
    name: 'Stream',
    components: {
        SideBarDesktop,
        Livestream,
        ImageHolder
    },
    data() {
      return {
    streams: {
        ArnoldHouse: { title: "Arnold House", subTitle: "MCCGLC", descTitle: "Hello and welcome to our new SPACEIN", descText: "Join Matteo, Richard and the rest of the SPACEIN team at the Soft Launch of SPACEIN", videoLink: "https://cdn.jwplayer.com/manifests/VPzYqBKo.m3u8", posterLink: "@/assets/streams/livestream-demo.jpg", slido: false, streamActive: true },
        EUniteforCVD: { title: "The #EUnite CVD events platform", subTitle: "Novartis", descTitle: "Welcome!", descText: "Welcome to The #EUnite CVD events platform.", videoLink: "https://cdn3.wowza.com/1/ZXU0NzZiWGVodnNV/WGFNQnRG/hls/live/playlist.m3u8", posterLink: "@/assets/streams/livestream-demo.jpg", slido: 'https://app.sli.do/event/iR4TCANRJfLSZymezz7tEG', streamActive: true, holder: '@/assets/streams/eunite-holder.png' },
        RehearsalEUniteforCVD: { title: "The #EUnite CVD events platform", subTitle: "Novartis", descTitle: "Welcome to the Rehearsal Space!", descText: "Welcome to The #EUnite CVD events platform.", videoLink: "https://cdn3.wowza.com/1/ZXU0NzZiWGVodnNV/WGFNQnRG/hls/live/playlist.m3u8", posterLink: "@/assets/streams/livestream-demo.jpg", slido: 'https://app.sli.do/event/iR4TCANRJfLSZymezz7tEG', streamActive: true }
        }
      }
    },
    created() {
      this.$mixpanel.track('View', {
      'site': "Guest",
      'page': "Livestream",
      'space': this.$route.params.streamID
    });
    },
    computed: { 
      roomInfo(){
        console.log(this.$route.params.streamID, this.streams[this.$route.params.streamID] )
      return this.streams[this.$route.params.streamID]
      }
    }
  }

</script>

<style scoped>
.fixedPosition {
  position:fixed;
  top:0;
  left:0;
  width: 100vw;
  height: 100vh;
}
</style>