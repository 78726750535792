<template>
<div class="imageholder">
    <div class="imageholder-video">
        <img class="imageholder-video__element" src="@/assets/streams/eunite-holder.png">
   </div>
</div>
</template>

<script>
export default {
  name: 'ImageHolder',
  // todo: fix error on refresh
  props: {
    roomData: Object
  },
}
</script>

<style lang="scss" scoped>

.imageholder {
    line-height: 1;
    display: flex;
    height: 100%;
    width: 100%;
    flex-flow: column;
    color: white;

    &-video {
        position: relative;
        flex-grow: 1;
        overflow: hidden;
        align-items: center;
        display: flex;

        &__element {
            width: 100%;
            height: auto;
            overflow: hidden;


            img {
                width: 100%;
                height: auto;
                object-fit: contain;
                object-position: center;
            }
        }
    }
}
</style>