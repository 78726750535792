<template>
  <div class="text-center">

    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      tile
      color="red accent-2"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="black"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
  export default {
    data: () => ({
      snackbar: true,
      text: 'It looks like you are using a mobile device. Scroll up to enlarge your screen',
      timeout: 8000,
    }),
  }
</script>