import Vue from 'vue'
import VueRouter from 'vue-router'
import Jitsi from '../views/Jitsi.vue'
import Stream from '../views/Stream.vue'
import Home from '../views/Home.vue'
import EndCall from '../views/EndCall.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Home 
  },
  { path: '/room/:roomID', 
    component: Jitsi 
  },
  { path: '/stream/:streamID', 
  component: Stream 
},
  { path: '/call-ended', 
    component: EndCall 
  },
  { path: '*',
    name: '404', 
    component: Home 
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
