import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

import VueMixpanelBrowser from 'vue-mixpanel-browser';

const mixpanelKey = '2fe8fa71a7387d4147f3210d300b8976';
// const mixpanelKey = 'none';

Vue.use(VueMixpanelBrowser, {
  token: mixpanelKey,
  config: {
    debug:false
  }
});


import '@/assets/css/video-js.css';

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
