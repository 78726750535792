<template>
<div>
  <div class="text-center">
    <v-dialog
      class="rounded-xl"
      v-model="dialog"
      width="100%"
      max-width="600px"
    >
      <v-card 
      class="rounded-xl pa-5 py-10 text-center"
      color="#2c2c2c" >
        <v-card-text>
          <p style="font-size:1.5em; font-weight:700; color:white;">This feature is not available when viewed as a Guest</p>
          To enable this and other collaborative features please register for SPACEIN. <br><br> You will receive an invitation to the full {{ details.title }} collaborative Space after the event.
        </v-card-text>

         <v-btn
            color="#fedb00"
            class="mx-3 spaceinButton"
            large
            light
            rounded
            @click="openSIN"
          >
            Go to SPACEIN
          </v-btn>

          <v-btn
            color="grey"
            large
            class="mx-3 spaceinButton"
            light
            rounded
            @click="toggleDialog"
          >
            Dismiss
          </v-btn>

          


      </v-card>
    </v-dialog>
  </div>


  <v-card
    height="100vh"
    width="390"
  >
    <v-navigation-drawer
      color="#2c2c2c"
      permanent
      width="100%"
      elevation="10"
      floating
    >
      <v-row
        class="fill-height"
        no-gutters
      >
        <v-navigation-drawer
        color="#141313"
        dark
        mini-variant
        mini-variant-width="80"
        permanent
        floating
        height="100vh"
        >

              <v-card
              width="80px"
              height="80px"
              class="py-4 ma-0 cardHover"
              v-for="item in items"
              :key="item.title"
              :color="item.color"
              flat
              :style="`${item.style?item.style:''} ${item.display?'':'display:none'}`"
              @click="item.action"
              >
                <v-img
                width="30px"
                class="mx-auto"
                :src="require(`@/assets/sidebar-icons/${item.icon}`)"
                />
                <p style="font-size:10px; text-align: center" class="pt-2">{{ item.title }}</p>
              </v-card>

        </v-navigation-drawer>

             <v-sheet
      v-if="this.sidebar=='qa'"
      color="transparent">
   
        <iframe class="frameHolder" :src="details.slido"></iframe>


       
          </v-sheet>

      <v-sheet
      v-else
      color="transparent">
        <v-card 
        color="transparent"
        class="py-5 pl-5 pr-10"
        elevation="0"
        height="100"
        width="310"
        >
        <h1 style="font-size:28px;line-height: 38px">{{ details.title }}</h1>
        <h2 style="color:#b3b3b3; font-size: 20px;" >{{ details.subTitle }}</h2>
        </v-card>

        <v-card
        color="#202020"
        width="270"
        class="mx-auto mt-16 mb-10 pa-5 rounded-xl"
        >
          <p style="font-weight: 700 " >{{ details.descTitle }}</p>
          <p style="color:#b3b3b3; line-height: 18px" >{{ details.descText }}</p>
        </v-card>

         <v-card
        color="#202020"
        width="270"
        class="mx-auto my-10 pa-5 rounded-xl"
        >
          <p style="font-weight: 700" class="blue--text">You are currently viewing this Stream as a Guest.</p>
          <p style="color:#b3b3b3; line-height: 20px" >Signing up to SPACEIN will activate all of the collaborative features available to you within {{ details.title }} Space.</p>
        </v-card>

          <p class="allRights">© SPACEIN Ltd {{ year }}. All rights reserved.</p>
       
          </v-sheet>


      </v-row>
    </v-navigation-drawer>
  </v-card>

  </div>
</template>

<script>
  export default {
    props: {
    details: Object,
    },
    data () {
      return {
        year: /\d{4}/.exec(Date())[0],
        dialog: false,
        sidebar: 'info',
        items: [
          { title: 'Overview', icon: 'return-icon.svg', color: 'transparent', style: 'opacity: 20%;  cursor: pointer;', display: true, action: this.toggleDialog },
          { title: 'Information', icon: 'info-icon.svg', color: '#2c2c2c', display: true, action: this.info },
          { title: 'Q&A / Polls', icon: 'poll-icon.svg', color: 'transparent', display: this.details.slido, action: this.slido },
          { title: 'Chat', icon: 'chat-icon.svg', color: 'transparent', style: 'opacity: 20%;  cursor: pointer;', display: true, action: this.toggleDialog },
          { title: 'Notifications', icon: 'notifications-icon.svg', color: 'transparent', style: 'opacity: 20%;  cursor: pointer;', display: true, action: this.toggleDialog },
          { title: 'Media Library', icon: 'links-icon.svg', color: 'transparent', style: 'opacity: 20%;  cursor: pointer;', display: true, action: this.toggleDialog },
        ],
        links: ['Home', 'Contacts', 'Settings'],
        mini: true,
      }
    },
    methods: {
      toggleDialog() {
        this.dialog = !this.dialog
      },
      slido() {
        this.sidebar = 'qa'
      },
      info() {
        this.sidebar = 'info'
      },
      openSIN(){
        window.open('https://app.spacein.co.uk')
      }
    }
  }
</script>

<style scoped>
.frameHolder {
  width: 310px;
  height: 100%;
  border: none;
}

.allRights {
  font-size: 13px;
  color: #b3b3b3;
  position: fixed;
  bottom: 0;
  left: 100px
}

.cardHover {
  border-radius: 0 !important;
  transition: all 0.25s;
}
.cardHover:hover {
  opacity: 100%;
  background-color:#2c2c2c !important;
}

.spaceinButton {
  font-weight: 700;
  text-transform: none;
  letter-spacing: normal;
}


</style>